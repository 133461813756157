.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**/

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 50px auto;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  text-align: center;
  color: #333;
}

input[type="text"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 10px;
}

.player-info {
  background-color: #e9f7e9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.record {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.record-item {
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.record-item.wins {
  border-color: #4CAF50;
  color: #4CAF50;
}

.record-item.draws {
  border-color: #FFA500;
  color: #FFA500;
}

.record-item.losses {
  border-color: #FF0000;
  color: #FF0000;
}

.matchup {
  margin-top: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.matchup h3 {
  margin: 0 0 10px 0;
  font-size: 1.1em;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.round-section h2 {
  color: #444;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 5px;
}

.tiebreaker {
  color: #666;
  font-size: 0.9em;
}